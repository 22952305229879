<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tablas Dinamicas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Consultas</li>
                  <li class="breadcrumb-item active">Dinamicos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card-body p-5">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="input-group">
                    <h1 class="m-0 text-dark text-center">
                      Tablero de Tablas Dinamicas
                    </h1>
                    <div class="input-group-append"></div>
                  </div>
                  <div class="btn-group float"></div>
                </div>
              </div>
              <div class="card-body">
                <div class="row clearfix">
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.dinamicos.dinamicoGuiaGeneral'
                          )
                        "
                        @click="pivotTableGuiaGeneral()"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fa fa-list fa-3x"
                              style="color: green"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Tabla Dinamica Guia</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <!-- <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-general-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.exportGeneral'
                          )
                        "
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-file-alt fa-3x"
                              style="color: #31c177"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte General</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-ofertas-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.ofertasExport'
                          )
                        "
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fab fa-buffer fa-3x"
                              style="color: #f7f413"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Lista Ofertas</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-programaciones-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.programacionExport'
                          )
                        "
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-clipboard-list fa-3x"
                              style="color: #1013cc"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Lista Programaciones</h4>
                        </div>
                      </button>
                    </div>
                  </div> -->
                </div>
                <!-- <div class="row clearfix">
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-solicitudes-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.solicitudExport'
                          )
                        "
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-business-time fa-3x"
                              style="color: #7917a3"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Solicitudes Diarias</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-nominacion"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.nominacionExport'
                          )
                        "
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-users fa-3x"
                              style="color: blue"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Nominaciones</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-postulacion-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.postulacionExport'
                          )
                        "
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-money-check fa-3x"
                              style="color: #e7c30f"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Informe Postulaciones</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-consolidado-transporte-export"
                        v-if="$store.getters.can('hidrocarburos.reportes.exportConsolidado')"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-bus fa-3x"
                              style="color: #0ef3f3"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Consolidado Transporte</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-enturne-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.enturneExport'
                          )
                        "
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-sign-in-alt fa-3x"
                              style="color: #1c0834"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Enturnes</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-ticket-export"
                        v-if="$store.getters.can('hidrocarburos.reportes.ticketsExport')"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-ticket-alt fa-3x"
                              style="color: #ee050c"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Exportar Ticket</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-inspecciones-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.exportInspecciones'
                          )
                        "
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-file-signature fa-3x"
                              style="color: #209009"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Inspecciones</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-adicional-export"
                        v-if="$store.getters.can('hidrocarburos.reportes.adicionales')"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-plus-circle fa-3x"
                              style="color: #7e14e1"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Adicionales</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DinamicoIndex",
  methods: {
    pivotTableGuiaGeneral() {
      return this.$router.push({
        name: "/Hidrocarburos/DinamicoGuiaGeneral",
      });
    },
  },
};
</script>
